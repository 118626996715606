@import 'colours';
@import 'fonts';

// This popover gets appended to the body, so component-scoped styling doesn't work

.identity-popover {
  margin-top: 30px;
  max-width: 400px;
  right: 20px !important;
  left: auto !important;
  top: 53px !important;
  position: fixed;
  transform: none !important;
  background: var(--identity-popover-bg-colour);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, var(--shadow-opacity-identity-popover-bg));
  border: 0;
  padding: 10px;
  font-family: $brand-font-family;
  z-index: 2001;

  .popover-body {
    color: var(--text-colour);
  }

  .arrow::after {
    border-bottom-color: var(--identity-popover-bg-colour);
  }

  h2 { font-size: 1.1rem; }

  .avatar {
    max-width: 72px;
    max-height: 72px;
    margin-right: 1rem;
    vertical-align: top;
    border: var(--identity-popover-avatar-border);
  }

  .main-details {
    display: inline-block;

    p { margin: .2rem 0; }
  }

  .other-advertisers {
    margin-top: 1rem;
    border-top: 1px solid var(--identity-popover-dividers-colour);
    padding-top: 1rem;

    .inner {
      max-height: 300px;
      overflow: auto;
    }

    h3 {
      font-size: 1.1rem;
      margin-top: 1.5rem;

      &:first-child {
        margin-top: 0;
      }
    }

    ul {
      float: none;
      margin: 0;

      li {
        display: block !important;
        line-height: inherit !important;

        a {
          text-decoration: none !important;
          color: var(--identity-popover-link-colour) !important;
          font-family: $brand-font-family;

          &:hover {
            color: var(--identity-popover-link-hover-colour) !important;
            transition: color .5s ease;

            i.fa {
              color: var(--identity-popover-org-icon-hover-colour) !important;
              transition: color .5s ease;
            }
          }

          i.fa {
            margin-right: .5rem;
            color: var(--identity-popover-org-icon-colour) !important;
            vertical-align: middle;
          }
        }
      }
    }
  }

  .button-row {
    margin-top: 1rem;
    border-top: 1px solid var(--identity-popover-dividers-colour);
    padding-top: 1rem;
    display: flex;
    align-items: center;

    .btn { height: 32px; }

    & > * {
      margin-left: 1rem;
      vertical-align: middle;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }
    }

    .theme {
      display: flex;
      align-items: center;
      white-space: nowrap;
      flex-grow: 1;
      justify-content: flex-end;

      span {
        margin-right: .4rem;
      }
    }
  }

  .popover-arrow {
    right: 16px !important;
    left: auto !important;
  }
}
