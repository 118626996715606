@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;700&family=Roboto:wght@300;400;700&family=Source+Sans+Pro:wght@400;600&family=Lato:wght@300;400;700;900&display=swap');

@font-face {
  font-family: 'Brand';
  //noinspection CssUnknownTarget
  src: url('https://cdn.machineadvertising.com/fonts/brand.woff2');
}

@font-face {
  font-family: 'Brand';
  //noinspection CssUnknownTarget
  src: url('https://cdn.machineadvertising.com/fonts/brand-bold.woff2');
  font-weight: bold;
}

@font-face {
  font-family: 'Brand';
  //noinspection CssUnknownTarget
  src: url('https://cdn.machineadvertising.com/fonts/brand-italic.woff2');
  font-style: italic;
}

@font-face {
  font-family: 'Brand';
  //noinspection CssUnknownTarget
  src: url('https://cdn.machineadvertising.com/fonts/brand-bold-italic.woff2');
  font-weight: bold;
  font-style: italic;
}

$fa-font-path: '~@fortawesome/fontawesome-free/webfonts';
$icon-font-path: '../../node_modules/bootstrap-sass/assets/fonts/bootstrap/';

$brand-font-family: 'Brand', 'Century Gothic', 'Apple Gothic', AppleGothic, 'URW Gothic L', 'Avant Garde', Futura, sans-serif !important;
$monospace-font-family: 'Inconsolata', sans-serif !important;
$labels-font-family: 'Source Sans Pro', sans-serif !important;
$tooltip-font-family: 'Source Sans Pro', sans-serif !important;
$form-controls-font-family: 'Source Sans Pro', sans-serif !important;
$form-labels-font-family: 'Source Sans Pro', sans-serif !important;
$date-range-picker-font-family: 'Source Sans Pro', sans-serif !important;
$table-font-family: 'Source Sans Pro', sans-serif !important;
$chart-tooltip-font-family: 'Inconsolata', sans-serif !important;
$chart-annotation-font-family: 'Inconsolata', sans-serif !important;
$chart-axis-font-family: 'Inconsolata', sans-serif !important;
$chart-legend-font-family: 'Inconsolata', sans-serif !important;
$help-article-font-family: 'Lato', sans-serif !important;
