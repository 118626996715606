@import 'colours';
@import 'fonts';

form.data-form {
  margin-top: -20px;
  padding: 0;
  max-width: 80rem;
  width: 100%;
}

.data-form {
  &.narrow {
    max-width: 60rem;
  }

  &.full-width {
    max-width: inherit;
  }

  h4 {
    text-transform: uppercase;
    letter-spacing: .03em;
  }

  .switches {
    margin: 0;

    > .row {
      border-top: 1px solid var(--form-switches-border-colour);
      padding-top: 1rem;

      &:first-child {
        border-top: 0;
        padding-top: 0;
      }

      .description {
        border-right: 1px solid var(--form-switches-border-colour);
        margin-bottom: 1rem;
      }

      > div:last-child.description,
      div:last-child .row .description {
        border-right: 0;
      }
    }
  }

  .row {
    width: 100%;
  }

  .small-top-padding {
    padding: 5px 0 0 !important;
  }

  fieldset {
    padding: 0 0 40px;
  }

  input,
  textarea,
  select {
    font-family: $form-controls-font-family;
  }

  span.required-label {
    position: absolute;
    right: 15px;
    top: 13px;
    font-family: $form-labels-font-family;
    color: var(--form-required-label-colour);
    font-size: 16px;

    i {
      color: $brand-secondary-colour;
      font-size: 6px;
      position: relative;
      top: -4px;
    }
  }

  .form-section-lead {
    margin-bottom: 2rem;
  }

  .form-group {
    margin: 0 0 30px;

    &.clear {
      clear: both;
    }

    &.form-title {
      position: relative;
      margin: 20px 0 30px;

      h4 {
        margin: 0;
        line-height: 40px;
        text-shadow: none;
        font-weight: normal;
        font-family: $form-labels-font-family;
        color: var(--form-title-colour);
        font-size: 17px;
        border-bottom: 1px solid var(--form-control-title-underline-colour);
      }
    }

    label {
      font-family: $form-labels-font-family;
      font-size: 16px;
      font-weight: normal;
      text-transform: uppercase;
      color: var(--text-colour);

      i.fa-star {
        color: $brand-secondary-colour;
        font-size: 6px;
        position: relative;
        top: -4px;
      }
    }

    input,
    .form-control {
      border: 1px solid var(--form-control-border-colour);
      background: var(--form-control-bg-colour);

      &:focus {
        border: 1px solid var(--form-control-focused-border-colour);
      }

      &:disabled,
      &[readonly] {
        background: var(--form-control-disabled-bg-colour);
      }
    }

    ng-select {
      .ui-select-multiple {
        min-height: 40px;
      }

      input.form-control {
        border: 0;
      }

      .btn-secondary,
      .btn-primary {
        width: auto;
        padding: 5px 10px;
        height: auto;
        min-width: 10px;

        a.close {
          color: $brand-secondary-colour;
          font-size: 20px;
          padding-left: 5px !important;
          position: relative;
          top: 3px;
        }
      }
    }

    datepicker {
      daypicker {
        display: block;
        width: 100%;

        table:first-child {
          width: 100%;
        }
      }

      .btn-secondary,
      .btn-primary {
        width: auto;
        padding: 5px 10px;
        height: auto;
        min-width: 10px;
        display: block;
      }

      b {
        display: inline-block;
        padding-top: 10px;
      }
    }

    timepicker {
      tr:first-child {
        a {
          padding: 0;
          margin: 0;
          height: 20px;
        }
      }

      tr:nth-child(2) {
        td {
          overflow: hidden;
          line-height: 30px;
          margin: 0;

          button {
            display: block;
            margin-left: 5px;
            color: $brand-secondary-colour;
            border-color: $brand-secondary-colour;
            font-family: $form-labels-font-family;
          }

          &:nth-child(2) {
            color: $white;
            line-height: 0;
          }
        }
      }

      tr:last-child {
        a {
          padding: 0;
          margin: 0;
          height: 17px;
          position: relative;
          overflow: hidden;
        }
      }
    }

    .date {
      line-height: 40px;
      text-align: center;

      i {
        color: $brand-secondary-colour;
        font-size: 26px;
      }
    }

    .radio-buttons {
      display: block;

      .btn-primary {
        min-width: 0;
        height: auto;
        margin-bottom: 0;
        background: var(--radio-button-bg-colour);
        color: var(--radio-button-colour);
        padding: .2rem 1rem;
        transition: none;

        &:hover {
          color: var(--radio-button-hover-colour);
        }

        &.active {
          background: $brand-secondary-colour;
          color: $white;
          border: 1px solid $brand-secondary-colour;

          &:hover {
            color: $white;
          }
        }
      }
    }

    textarea {
      border: 1px solid var(--form-control-border-colour);
      background: var(--form-control-bg-colour);
      resize: vertical;
      min-height: 38px;

      &:focus {
        border: 1px solid var(--form-control-focused-border-colour);
      }
    }

    .form-validation {
      background: var(--form-validation-bg-colour);
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      &.floating {
        margin-top: .7rem;
        border-radius: 4px;
      }

      p {
        padding: 4px 8px;
        color: $white;
        font-size: 12px;
      }
    }
  }
}

.end-buttons {
  margin-top: 2rem !important;
  display: flex;
  align-items: center;

  &.sticky {
    margin-top: 0 !important;
  }

  a {
    margin-left: 2.5rem;
  }

  button {
    margin-left: 1.25rem;

    &:first-child { margin-left: 0; }
  }

  .form-validation {
    background: var(--form-validation-bg-colour);
    border-radius: 4px;
    color: $white;

    p {
      padding: 4px 8px;
      font-size: 12px;
      display: flex;
      align-items: center;

      &::before {
        font-family: 'Font Awesome 6 Free', sans-serif;
        font-weight: 900;
        font-size: 16px;
        content: '\f071';
        margin-right: .3rem;
      }
    }
  }
}
