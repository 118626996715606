@import 'fonts';
@import 'functions';
@import 'selectors';

// sass-lint:disable no-vendor-prefixes

@mixin clearfix {
  &::after { content: '';
    display: table;
    clear: both;
  }
}

@mixin hide {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

@mixin ellipsis($width: 100%) {
  display: inline-block;
  max-width: $width;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}

@mixin word-wrap($wrap: break-word) {
  overflow-wrap: $wrap;
  word-wrap: $wrap;

  @if $wrap == break-word {
    word-break: break-all;
  } @else {
    word-break: $wrap;
  }
}

@mixin full-height-content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 70px);
}
