::-webkit-scrollbar {
  width: 18px;
  height: 18px;
}

::-webkit-scrollbar-track {
  background: var(--scrollbar-track-colour);
  &:vertical { border-left: var(--scrollbar-track-border); }
  &:horizontal { border-top: var(--scrollbar-track-border); }
}

::-webkit-scrollbar-corner {
  background: var(--scrollbar-corner-colour);
}

::-webkit-resizer {
  border: 0;
  //noinspection CssUnknownTarget
  background: url('/assets/images/resizer.svg') no-repeat bottom right;
  background-size: 12px;
}

::-webkit-scrollbar-thumb {
  min-height: 40px;
  min-width: 40px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  background-color: var(--scrollbar-thumb-colour);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

body {
  ::-webkit-scrollbar-track {
    background: transparent;
    border-left: 0 !important;
    border-top: 0 !important;;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }
}
