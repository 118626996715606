@import 'colours';
@import 'fonts';

// This popover gets appended to the body, so component-scoped styling doesn't work

.page-settings-popover {
  margin-top: 30px;
  max-width: 400px;
  background: var(--identity-popover-bg-colour);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, var(--shadow-opacity-identity-popover-bg));
  border: 0;
  padding: 10px;
  font-family: $brand-font-family;
  z-index: 2001;

  .popover-body {
    color: var(--text-colour);
  }

  .arrow::after {
    border-bottom-color: var(--identity-popover-bg-colour);
  }

  h2 { font-size: 1.1rem; }

  .button-row {
    margin-top: 1rem;
    border-top: 1px solid var(--identity-popover-dividers-colour);
    padding-top: 1rem;
    display: flex;
    align-items: center;

    .btn { height: 32px; }

    & > * {
      margin-left: 1rem;
      vertical-align: middle;
      display: inline-block;

      &:first-child {
        margin-left: 0;
      }
    }

    .theme {
      display: flex;
      align-items: center;
      white-space: nowrap;
      flex-grow: 1;
      justify-content: flex-end;

      span {
        margin-right: .4rem;
      }
    }
  }

  .popover-arrow {
    right: 8px !important;
    left: auto !important;
  }
}
