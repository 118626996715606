body {
  --alert-secondary-bg-colour: #e2e3e5;
  --alert-secondary-border: 1px solid #d6d8db;
  --alert-warning-bg-colour: #fff3cd;
  --alert-warning-border-colour: #ffeeba;
  --alert-warning-colour: #856404;
  --allocation-bg-colour-right: linear-gradient(to right, #777 0%, #555 100%);
  --allocation-bg-colour: linear-gradient(to bottom, #777 0%, #555 100%);
  --badge-info-bg-colour: #337ab7;
  --badge-info-colour: #fff;
  --badge-subtle-bg-colour: rgba(0, 0, 0, .04);
  --bar-chart-colour-1: #2984c2;
  --bar-chart-colour-2: #ff8c00;
  --bg-colour: #fff;
  --blade-bg-colour: #fff;
  --blade-border: 0;
  --blade-box-shadow: 0 0 40px 0 rgba(0, 0, 0, .4);
  --blade-evidence-bg-colour: #eee;
  --blade-evidence-title-bg-colour: #ddd;
  --border-colour: #c9c9c9;
  --btn-danger-subtle-border-colour: #a91523;
  --btn-danger-subtle-colour: #555;
  --btn-danger-subtle-hover-colour: #333;
  --btn-secondary-bg-colour: #fff;
  --btn-secondary-bg-hover-colour: #fff;
  --btn-secondary-border-colour: #d98046;
  --btn-secondary-colour: #d98046;
  --btn-secondary-text-hover-colour: #d98046;
  --btn-text-colour: #222;
  --bubble-border-colour: #ccc;
  --bubble-colour: #777;
  --bubble-green-bg-colour: #007a0b;
  --bubble-green-colour: #fff;
  --bubble-grey-bg-colour: #999;
  --bubble-grey-colour: #121212;
  --bubble-red-bg-colour: #a91523;
  --bubble-red-colour: #fff;
  --bubble-yellow-bg-colour: #ba7f00;
  --bubble-yellow-colour: #121212;
  --busy-indicator-progress-circle-bg-colour: rgba(34, 34, 34, .2);
  --busy-indicator-progress-circle-colour: #222;
  --callout-bg-colour: #eee;
  --callout-form-control-bg-colour: #fff;
  --callout-form-control-border-colour: #c9c9c9;
  --callout-form-control-disabled-bg-colour: #e4e4e4;
  --callout-form-control-disabled-border-colour: #c9c9c9;
  --callout-form-control-invalid-bg-colour: #fee;
  --callout-form-control-invalid-border: 1px solid #a91523;
  --callout-form-control-warn-bg-colour: #fffaee;
  --callout-form-control-warn-border: 1px solid #fcbf79;
  --chart-bg-colour: #ebebeb;
  --chart-border-colour: #ddd;
  --chart-floating-axis-title-bg-colour: rgb(255, 255, 255, .65);
  --chart-floating-axis-title-border-colour: rgb(0, 0, 0, .2);
  --chart-floating-axis-title-colour: #000;
  --chart-grid-bg-image: linear-gradient(to right, rgb(0, 0, 0, .08) 1px, transparent 1px), linear-gradient(to bottom, rgb(0, 0, 0, .08) 1px, transparent 1px);
  --chart-no-data-colour: #ccc;
  --chart-tiled-bg-colour: #f8f8f8;
  --chart-tiled-box-shadow: none;
  --chart-tiled-plot-bg-colour: #ddd;
  --chart-title-bg-colour: #ebebeb;
  --chart-tooltip-bg-colour: #fff;
  --chart-tooltip-border-colour: #666;
  --chart-tooltip-box-shadow: 0 0 5px 0 rgba(0, 0, 0, .3);
  --chart-tooltip-font-weight: 400;
  --chart-tooltip-header-bg-colour: #f2f2f2;
  --content-header-underline-colour: #666;
  --content-tabs-active-bg-colour: #bbb;
  --content-tabs-active-border-colour: #777;
  --content-tabs-bottom-border-colour: #666;
  --cost-aim-colour: #2984c2;
  --cost-mmp-colour: #fba747;
  --data-table-active-even-rows-bg-colour: #fff8e3;
  --data-table-active-even-rows-bg-colour-emphasis: #ebd9da;
  --data-table-active-row-bg-colour: #fffcf5;
  --data-table-active-row-bg-colour-emphasis: #ebdde9;
  --data-table-bg-colour: #fff;
  --data-table-bg-colour-emphasis: #ebdff2;
  --data-table-border-colour: #e5e5e5;
  --data-table-callout-bg-colour: #edf7ff;
  --data-table-callout-border-colour: #337ab7;
  --data-table-category-bg-colour: #b8b8b8;
  --data-table-category-header-bg-colour: #333;
  --data-table-category-header-first-column-bg-colour: #333;
  --data-table-column-group-separator-colour: #ccc;
  --data-table-column-header-group-separator-colour: #aaa;
  --data-table-column-separator-colour: #e5e5e5;
  --data-table-divider-colour: #ccc;
  --data-table-even-rows-bg-colour: #f2f2f2;
  --data-table-even-rows-bg-colour-emphasis: #dfd4e7;
  --data-table-first-column-bg-colour: #f5f5f5;
  --data-table-first-column-bg-colour-emphasis: #e2d7e9;
  --data-table-first-column-even-rows-bg-colour: #e6e6e6;
  --data-table-first-column-even-rows-bg-colour-emphasis: #d5cadc;
  --data-table-first-column-hovered-row-bg-colour: #fff4d6;
  --data-table-first-column-hovered-row-bg-colour-emphasis: #ebd6cf;
  --data-table-footer-bg-colour: #f8f8f8;
  --data-table-footer-first-column-bg-colour: #ccc;
  --data-table-footer-no-data-bg-colour: #f8f8f8;
  --data-table-header-bg-colour: #ddd;
  --data-table-header-first-column-bg-colour: #ccc;
  --data-table-hovered-row-bg-colour: #fff8e6;
  --data-table-hovered-row-bg-colour-emphasis: #ebd9dc;
  --data-table-link-colour: #d98046;
  --data-table-pagination-info-colour: #888;
  --data-table-row-separator-colour: #e5e5e5;
  --data-table-two-value-separator-colour: #e5e5e5;
  --daterangepicker-available-hover-colour: #eee;
  --daterangepicker-bg-colour: #fff;
  --daterangepicker-border-colour: #ddd;
  --daterangepicker-box-shadow: 0 2px 15px 0 rgb(0 0 0 / 25%);
  --daterangepicker-calendar-table-bg-colour: #fff;
  --daterangepicker-calendar-table-border-colour: #fff;
  --daterangepicker-disabled-date-colour: #999;
  --daterangepicker-divider-colour: #ddd;
  --daterangepicker-in-range-bg-colour: #ebf4f8;
  --daterangepicker-in-range-colour: #000;
  --daterangepicker-inverted-text-colour: #ccc;
  --daterangepicker-range-hover-colour: #eee;
  --drop-zone-border-colour: #ccc;
  --dropdown-divider-border: 1px solid rgba(127, 127, 127, 0.25);
  --dropdown-item-bg-colour: rgba(0, 0, 0, .1);
  --dropdown-menu-bg-colour: #fff;
  --dropdown-menu-border: 1px solid rgba(0, 0, 0, 0.15);
  --dropdown-menu-box-shadow: none;
  --end-buttons-bg-colour: #fff;
  --end-buttons-border-colour: #ccc;
  --form-control-bg-colour: #f8f8f8;
  --form-control-border-colour: #c9c9c9;
  --form-control-checkbox-checked-colour: #337ab7;
  --form-control-checkbox-hover-colour: #bbb;
  --form-control-checkbox-unchecked-colour: #aaa;
  --form-control-colour: #444;
  --form-control-disabled-bg-colour: #e4e4e4;
  --form-control-disabled-border-colour: #c9c9c9;
  --form-control-disabled-colour: #999;
  --form-control-focused-border-colour: #aaa;
  --form-control-text-placeholder-colour: #aaa;
  --form-control-title-underline-colour: #999;
  --form-required-label-colour: #999;
  --form-switches-border-colour: #eee;
  --form-table-input-invalid-bg-colour: #fee;
  --form-table-input-invalid-border-colour: #a00;
  --form-title-colour: #999;
  --form-validation-bg-colour: #a91523;
  --header-bg: #f2f2f2;
  --header-border: 1px solid #999;
  --headline-box-bg-colour: #eee;
  --high-performance-bg-colour: #6ed176;
  --high-performance-dim-bg-colour: rgba(110, 209, 118, .8);
  --history-blade-item-bg-colour: #f2f2f2;
  --history-blade-item-model-bg-colour: #fff;
  --history-blade-item-model-border-colour: rgba(0, 0, 0, .2);
  --history-blade-item-model-header-bg-colour: #f2f2f2;
  --identity-popover-avatar-border: 0;
  --identity-popover-bg-colour: #fff;
  --identity-popover-dividers-colour: #ccc;
  --identity-popover-link-colour: #333;
  --identity-popover-link-hover-colour: #666;
  --identity-popover-org-icon-colour: #999;
  --identity-popover-org-icon-hover-colour: #666;
  --key-stats-bg-colour: #f2f2f2;
  --key-stats-divider-colour: #bbb;
  --key-stats-header-divider-colour: #ccc;
  --key-stats-horizontal-divider-colour: #ccc;
  --key-stats-pinned-bg-colour: #e2e2e2;
  --key-stats-pinned-divider-colour: #bbb;
  --label-tags-colour: #555;
  --label-tags-danger-colour: #a91523;
  --label-tags-info-colour: #337ab7;
  --label-tags-success-colour: #007a0b;
  --label-tags-success-text-colour: #fff;
  --label-tags-warning-colour: #ba7f00;
  --low-performance-bg-colour: #bbb;
  --low-performance-dim-bg-colour: #bbb;
  --modal-bg-colour: #fff;
  --modal-border: 1px solid rgba(0, 0, 0, 0.2);
  --modal-box-shadow: 0;
  --modal-close-colour: #000;
  --modal-close-shadow-colour: #fff;
  --modal-divider-colour: #dee2e6;
  --modal-picklist-item-bg-colour: #f8f8f8;
  --navigation-bg-colour: #000;
  --network-icon-list-opacity: 1;
  --network-tile-tag-bg-colour: #fff;
  --ng-dropdown-header-border-colour: #ccc;
  --ng-dropdown-panel-bg-colour: #fff;
  --ng-dropdown-panel-border-colour: #ccc;
  --ng-dropdown-panel-border-top-bottom-colour: #e6e6e6;
  --ng-option-marked-bg-colour: #f5faff;
  --ng-select-container-bg-colour: #f8f8f8;
  --ng-select-container-border-colour: #ccc;
  --ng-select-container-focused-border-colour: #aaa;
  --ng-select-value-divider-colour: #b8dbff;
  --optimum-spend-above-maximum-bg-colour-right: linear-gradient(to right, #fc7979 0%, #fb4747 100%);
  --optimum-spend-above-maximum-bg-colour: linear-gradient(to bottom, #fc7979 0%, #fb4747 100%);
  --optimum-spend-below-minimum-bg-colour-right: linear-gradient(to right, #eee 0%, #d5d5d5 100%);
  --optimum-spend-below-minimum-bg-colour: linear-gradient(to bottom, #eee 0%, #d5d5d5 100%);
  --optimum-spend-colour: #6eae73;
  --optimum-spend-maximum-bg-colour-right: linear-gradient(to right, #fcbf79 0%, #fba747 100%);
  --optimum-spend-maximum-bg-colour: linear-gradient(to bottom, #fcbf79 0%, #fba747 100%);
  --optimum-spend-minimum-bg-colour-right: linear-gradient(to right, #afdefa 0%, #7fcaf7 100%);
  --optimum-spend-minimum-bg-colour: linear-gradient(to bottom, #afdefa 0%, #7fcaf7 100%);
  --optimum-spend-recommended-bg-colour-right: linear-gradient(to right, #c0f28f 0%, #a6ed61 100%);
  --optimum-spend-recommended-bg-colour: linear-gradient(to bottom, #c0f28f 0%, #a6ed61 100%);
  --page-bg-colour-special: #f2f2f2;
  --page-bg-colour: #fff;
  --page-secondary-tabs-bg-colour: #fafafa;
  --page-secondary-tabs-border-bottom: 1px solid #ddd;
  --page-secondary-tabs-border-top: 1px solid #ddd;
  --page-tabs-border: 2px solid #000;
  --page-third-level-tabs-bg-colour: #fff;
  --page-third-level-tabs-border-bottom: 1px solid #ddd;
  --page-third-level-tabs-border-top: 0;
  --page-top-filters-bg-colour: #eee;
  --page-top-filters-border-top: 0;
  --page-top-filters-form-control-border-colour: #adadad;
  --page-top-filters-label-colour: #777;
  --page-top-filters-radio-active-colour: #333;
  --page-top-filters-radio-buttons-active-bg-colour: #f8f8f8;
  --page-top-filters-radio-buttons-active-border: 1px solid #999;
  --page-top-filters-radio-buttons-bg-colour: #ccc;
  --page-top-filters-radio-buttons-border: 1px solid #bbb;
  --page-top-filters-radio-colour: #777;
  --profile-border-colour: #ccc;
  --profile-properties-border-colour: #ccc;
  --profile-property-name-bg-colour: #eee;
  --progress-bg-colour: #ccc;
  --progress-bg: #ccc;
  --progress-region-current-step-busy-indicator-opacity: .4;
  --progress-region-not-started-busy-indicator-opacity: .8;
  --progression-over-allocation-text-colour: #a91523;
  --progression-target-box-shadow-colour: #fff;
  --radio-button-bg-colour: #fff;
  --radio-button-border: 1px solid #999;
  --radio-button-colour: #999;
  --radio-button-hover-colour: #888;
  --saturation-point-colour: #fba747;
  --score-bad-colour: #a91523;
  --score-bad-text-colour: #fff;
  --score-border-colour: #ccc;
  --score-colour: #777;
  --score-good-colour: #007a0b;
  --score-good-text-colour: #fff;
  --score-medium-colour: #ba7f00;
  --score-medium-text-colour: #222;
  --scrollbar-corner-colour: #eee;
  --scrollbar-thumb-colour: rgba(0, 0, 0, .35);
  --scrollbar-track-border: 1px solid #ddd;
  --scrollbar-track-colour: #fff;
  --search-results-bg-colour: #f8f8f8;
  --search-results-border: 1px solid #ddd;
  --search-results-divider-colour: #ddd;
  --search-results-hover-colour: #fff;
  --shadow-opacity-identity-popover-bg: .25;
  --slider-handle-border-colour: #fff;
  --slider-handle-box-shadow: 0 0 10px 0 rgb(0, 0, 0, .2);
  --slider-selection-bg-colour: #ccc;
  --slider-selection-bg-image: linear-gradient(to bottom, #ccc, #bbb);
  --slider-track-bg-colour: #bbb;
  --slider-track-bg-image: linear-gradient(to bottom, #bbb, #ccc);
  --spend-colour: #38afff;
  --standard-performance-bg-colour: #2984c2;
  --standard-performance-dim-bg-colour: rgba(41, 132, 194, .4);
  --switch-bg-colour: #aaa;
  --text-colour-danger: #cc182a;
  --text-colour-inverted: #ccc;
  --text-colour-max: #000;
  --text-colour-muted: #999;
  --text-colour-strong: #000;
  --text-colour-success: #007a0b;
  --text-colour-warning: #ba7f00;
  --text-colour: #222;
  --tile-bg-colour: #f2f2f2;
  --tile-callout-bg-colour: #ebebeb;
  --tile-drag-placeholder-border-colour: #bbb;
  --tile-divider-colour: #ccc;
  --tile-header-divider-colour: #ccc;
  --tile-with-chart-bg-colour: #f8f8f8;
  --tooltip-bg-colour: #555;
  --tooltip-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .15);
  --tooltip-colour: #fff;
  --version-colour: #999;
  --work-in-progress-bg-colour: #eee;
  --work-in-progress-border: 5px dashed #ddd;

  //********************************************************************************************
  // DARK THEME
  //********************************************************************************************
  &.dark {
    --alert-secondary-border: 1px solid #383838;
    --alert-warning-bg-colour: #403d26;
    --alert-warning-border-colour: #66613d;
    --alert-warning-colour: #fff;
    --allocation-bg-colour-right: linear-gradient(to right, #777 0%, #444 100%);
    --allocation-bg-colour: linear-gradient(to bottom, #777 0%, #444 100%);
    --badge-info-bg-colour: #337ab7;
    --badge-info-colour: #fff;
    --badge-subtle-bg-colour: rgba(255, 255, 255, .06);
    --bar-chart-colour-1: #38afff;
    --bar-chart-colour-2: #ff8c00;
    --bg-colour: #303030;
    --blade-bg-colour: #222;
    --blade-border: 1px solid #333;
    --blade-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 1);
    --blade-evidence-bg-colour: #303030;
    --blade-evidence-title-bg-colour: #444;
    --border-colour: #444;
    --btn-danger-subtle-border-colour: #db4d5a;
    --btn-danger-subtle-colour: #aaa;
    --btn-danger-subtle-hover-colour: #ccc;
    --btn-secondary-bg-colour: #383838;
    --btn-secondary-bg-hover-colour: #484848;
    --btn-secondary-border-colour: #a86132;
    --btn-secondary-colour: #aaa;
    --btn-secondary-text-hover-colour: #ccc;
    --btn-text-colour: #fff;
    --bubble-border-colour: #666;
    --bubble-colour: #ccc;
    --bubble-green-bg-colour: #63c96c;
    --bubble-green-colour: #121212;
    --bubble-grey-bg-colour: #777;
    --bubble-grey-colour: #121212;
    --bubble-red-bg-colour: #db4d5a;
    --bubble-red-colour: #121212;
    --bubble-yellow-bg-colour: #e6b243;
    --bubble-yellow-colour: #121212;
    --busy-indicator-progress-circle-bg-colour: rgba(208, 208, 208, .2);
    --busy-indicator-progress-circle-colour: #ccc;
    --callout-bg-colour: #303030;
    --callout-form-control-bg-colour: #303030;
    --callout-form-control-border-colour: #404040;
    --callout-form-control-disabled-bg-colour: #444;
    --callout-form-control-disabled-border-colour: #555;
    --callout-form-control-invalid-bg-colour: #303030;
    --callout-form-control-invalid-border: 2px solid #db4d5a;
    --callout-form-control-warn-bg-colour: #303030;
    --callout-form-control-warn-border: 2px solid #ba7f00;
    --chart-bg-colour: #222;
    --chart-border-colour: #383838;
    --chart-floating-axis-title-bg-colour: rgb(0, 0, 0, .65);
    --chart-floating-axis-title-border-colour: rgb(255, 255, 255, .25);
    --chart-floating-axis-title-colour: #aaa;
    --chart-grid-bg-image: linear-gradient(to right, rgb(255, 255, 255, .08) 1px, transparent 1px), linear-gradient(to bottom, rgb(255, 255, 255, .08) 1px, transparent 1px);
    --chart-no-data-colour: #444;
    --chart-tiled-bg-colour: #181818;
    --chart-tiled-box-shadow: 0 0 10px 0 rgb(0 0 0 / 25%);
    --chart-tiled-plot-bg-colour: #181818;
    --chart-title-bg-colour: #222;
    --chart-tooltip-bg-colour: #181818;
    --chart-tooltip-border-colour: #444;
    --chart-tooltip-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .75);
    --chart-tooltip-font-weight: 300;
    --chart-tooltip-header-bg-colour: #303030;
    --content-header-underline-colour: #555;
    --content-tabs-active-bg-colour: #333;
    --content-tabs-active-border-colour: #666;
    --content-tabs-bottom-border-colour: #555;
    --cost-aim-colour: #38afff;
    --cost-mmp-colour: #ab5d00;
    --data-table-active-even-rows-bg-colour: #1a1f29;
    --data-table-active-even-rows-bg-colour-emphasis: #261f3a;
    --data-table-active-row-bg-colour: #1d232e;
    --data-table-active-row-bg-colour-emphasis: #29233f;
    --data-table-bg-colour: #181818;
    --data-table-bg-colour-emphasis: #25192c;
    --data-table-border-colour: #303030;
    --data-table-callout-bg-colour: #2e2630;
    --data-table-callout-border-colour: #6e5b73;
    --data-table-category-bg-colour: #555;
    --data-table-category-header-bg-colour: #444;
    --data-table-category-header-first-column-bg-colour: #444;
    --data-table-column-group-separator-colour: #383838;
    --data-table-column-header-group-separator-colour: #505050;
    --data-table-column-separator-colour: #383838;
    --data-table-divider-colour: #666;
    --data-table-even-rows-bg-colour: #222;
    --data-table-even-rows-bg-colour-emphasis: #2d2234;
    --data-table-first-column-bg-colour: #202020;
    --data-table-first-column-bg-colour-emphasis: #2b2033;
    --data-table-first-column-even-rows-bg-colour: #292929;
    --data-table-first-column-even-rows-bg-colour-emphasis: #33283a;
    --data-table-first-column-hovered-row-bg-colour: #2b3445;
    --data-table-first-column-hovered-row-bg-colour-emphasis: #353152;
    --data-table-footer-bg-colour: #181818;
    --data-table-footer-first-column-bg-colour: #393939;
    --data-table-footer-no-data-bg-colour: #181818;
    --data-table-header-bg-colour: #303030;
    --data-table-header-first-column-bg-colour: #393939;
    --data-table-hovered-row-bg-colour: #222936;
    --data-table-hovered-row-bg-colour-emphasis: #2d2846;
    --data-table-link-colour: #d98046;
    --data-table-pagination-info-colour: #888;
    --data-table-row-separator-colour: #303030;
    --data-table-two-value-separator-colour: #303030;
    --daterangepicker-available-hover-colour: #444;
    --daterangepicker-bg-colour: #303030;
    --daterangepicker-border-colour: #303030;
    --daterangepicker-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 1);
    --daterangepicker-calendar-table-bg-colour: #303030;
    --daterangepicker-calendar-table-border-colour: #303030;
    --daterangepicker-disabled-date-colour: #777;
    --daterangepicker-divider-colour: #666;
    --daterangepicker-in-range-bg-colour: #304a59;
    --daterangepicker-in-range-colour: #fff;
    --daterangepicker-inverted-text-colour: #333;
    --daterangepicker-range-hover-colour: #444;
    --drop-zone-border-colour: #666;
    --dropdown-divider-border: 1px solid rgba(127, 127, 127, 0.25);
    --dropdown-item-bg-colour: rgba(255, 255, 255, .1);
    --dropdown-menu-bg-colour: #303030;
    --dropdown-menu-border: 1px solid rgba(255, 255, 255, 0.15);
    --dropdown-menu-box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .5);
    --end-buttons-bg-colour: #1b1b1b;
    --end-buttons-border-colour: #666;
    --form-control-bg-colour: #222;
    --form-control-border-colour: #383838;
    --form-control-checkbox-checked-colour: #337ab7;
    --form-control-checkbox-hover-colour: #666;
    --form-control-checkbox-unchecked-colour: #555;
    --form-control-colour: #bbb;
    --form-control-disabled-bg-colour: #484848;
    --form-control-disabled-border-colour: #666;
    --form-control-disabled-colour: #aaa;
    --form-control-focused-border-colour: #666;
    --form-control-text-placeholder-colour: #777;
    --form-control-title-underline-colour: #444;
    --form-required-label-colour: #999;
    --form-switches-border-colour: #333;
    --form-table-input-invalid-bg-colour: #400;
    --form-table-input-invalid-border-colour: #cc182a;
    --form-title-colour: #999;
    --form-validation-bg-colour: #750e18;
    --header-bg: #202020;
    --header-border: 1px solid #303030;
    --headline-box-bg-colour: #222;
    --high-performance-bg-colour: #6ed176;
    --high-performance-dim-bg-colour: rgba(110, 209, 118, .4);
    --history-blade-item-bg-colour: #383838;
    --history-blade-item-model-bg-colour: #222;
    --history-blade-item-model-border-colour: rgba(255, 255, 255, .2);
    --history-blade-item-model-header-bg-colour: #383838;
    --identity-popover-avatar-border: 1px solid #444;
    --identity-popover-bg-colour: #303030;
    --identity-popover-dividers-colour: #666;
    --identity-popover-link-colour: #999;
    --identity-popover-link-hover-colour: #ccc;
    --identity-popover-org-icon-colour: #666;
    --identity-popover-org-icon-hover-colour: #999;
    --key-stats-bg-colour: #222;
    --key-stats-divider-colour: #444;
    --key-stats-header-divider-colour: #121212;
    --key-stats-horizontal-divider-colour: #333;
    --key-stats-pinned-bg-colour: #303030;
    --key-stats-pinned-divider-colour: #555;
    --label-tags-colour: #555;
    --label-tags-danger-colour: #db4d5a;
    --label-tags-info-colour: #337ab7;
    --label-tags-success-colour: #63c96c;
    --label-tags-success-text-colour: #000;
    --label-tags-warning-colour: #e6b243;
    --low-performance-bg-colour: #444;
    --low-performance-dim-bg-colour: #555;
    --modal-bg-colour: #202020;
    --modal-border: 1px solid #383838;
    --modal-box-shadow: 0 0 40px 0 rgba(0, 0, 0, 1);
    --modal-close-colour: #fff;
    --modal-close-shadow-colour: #000;
    --modal-divider-colour: #383838;
    --modal-picklist-item-bg-colour: #303030;
    --navigation-bg-colour: #222;
    --network-icon-list-opacity: .75;
    --network-tile-tag-bg-colour: #303030;
    --ng-dropdown-header-border-colour: #555;
    --ng-dropdown-panel-bg-colour: #303030;
    --ng-dropdown-panel-border-colour: #555;
    --ng-dropdown-panel-border-top-bottom-colour: #666;
    --ng-option-marked-bg-colour: #444;
    --ng-select-container-bg-colour: #222;
    --ng-select-container-border-colour: #383838;
    --ng-select-container-focused-border-colour: #666;
    --ng-select-value-divider-colour: #222;
    --optimum-spend-above-maximum-bg-colour-right: linear-gradient(to right, #ad4242 0%, #751c1c 100%);
    --optimum-spend-above-maximum-bg-colour: linear-gradient(to bottom, #ad4242 0%, #751c1c 100%);
    --optimum-spend-below-minimum-bg-colour-right: linear-gradient(to right, #444 0%, #333 100%);
    --optimum-spend-below-minimum-bg-colour: linear-gradient(to bottom, #444 0%, #333 100%);
    --optimum-spend-colour: #007a0b;
    --optimum-spend-maximum-bg-colour-right: linear-gradient(to right, #b37a39 0%, #825119 100%);
    --optimum-spend-maximum-bg-colour: linear-gradient(to bottom, #b37a39 0%, #825119 100%);
    --optimum-spend-minimum-bg-colour-right: linear-gradient(to right, #3b82ad 0%, #2a6a91 100%);
    --optimum-spend-minimum-bg-colour: linear-gradient(to bottom, #3b82ad 0%, #2a6a91 100%);
    --optimum-spend-recommended-bg-colour-right: linear-gradient(to right, #70a33e 0%, #446e1b 100%);
    --optimum-spend-recommended-bg-colour: linear-gradient(to bottom, #70a33e 0%, #446e1b 100%);
    --page-bg-colour-special: #121212;
    --page-bg-colour: #121212;
    --page-secondary-tabs-bg-colour: #222;
    --page-secondary-tabs-border-bottom: 1px solid #000;
    --page-secondary-tabs-border-top: 1px solid #555;
    --page-tabs-border: 1px solid #000;
    --page-third-level-tabs-bg-colour: #1a1a1a;
    --page-third-level-tabs-border-bottom: 1px solid #333;
    --page-third-level-tabs-border-top: 1px solid #555;
    --page-top-filters-bg-colour: #202020;
    --page-top-filters-border-top: 1px solid #000;
    --page-top-filters-form-control-border-colour: #555;
    --page-top-filters-label-colour: #777;
    --page-top-filters-radio-active-colour: #333;
    --page-top-filters-radio-buttons-active-bg-colour: #999;
    --page-top-filters-radio-buttons-active-border: 1px solid #999;
    --page-top-filters-radio-buttons-bg-colour: #202020;
    --page-top-filters-radio-buttons-border: 1px solid #555;
    --page-top-filters-radio-colour: #777;
    --profile-border-colour: #444;
    --profile-properties-border-colour: #444;
    --profile-property-name-bg-colour: #222;
    --progress-bg-colour: #383838;
    --progress-bg: #333;
    --progress-region-current-step-busy-indicator-opacity: 1;
    --progress-region-not-started-busy-indicator-opacity: .4;
    --progression-over-allocation-text-colour: #d6747c;
    --progression-target-box-shadow-colour: #121212;
    --radio-button-bg-colour: transparent;
    --radio-button-border: 1px solid #666;
    --radio-button-colour: #999;
    --radio-button-hover-colour: #aaa;
    --saturation-point-colour: #ab5d00;
    --score-bad-colour: #db4d5a;
    --score-bad-text-colour: #222;
    --score-border-colour: #444;
    --score-colour: #999;
    --score-good-colour: #63c96c;
    --score-good-text-colour: #222;
    --score-medium-colour: #e6b243;
    --score-medium-text-colour: #222;
    --scrollbar-corner-colour: #292929;
    --scrollbar-thumb-colour: rgba(255, 255, 255, .15);
    --scrollbar-track-border: 1px solid #444;
    --scrollbar-track-colour: #333;
    --search-results-bg-colour: #222;
    --search-results-border: 1px solid #383838;
    --search-results-divider-colour: #383838;
    --search-results-hover-colour: #303030;
    --shadow-opacity-identity-popover-bg: .75;
    --slider-handle-border-colour: #ccc;
    --slider-handle-box-shadow: 0 0 10px 0 rgb(0, 0, 0, .4);
    --slider-selection-bg-colour: #555;
    --slider-selection-bg-image: linear-gradient(to bottom, #555, #444);
    --slider-track-bg-colour: #444;
    --slider-track-bg-image: linear-gradient(to bottom, #444, #555);
    --spend-colour: #38afff;
    --standard-performance-bg-colour: #38afff;
    --standard-performance-dim-bg-colour: rgba(56, 175, 255, .4);
    --switch-bg-colour: #555;
    --text-colour-danger: #db4d5a;
    --text-colour-inverted: #222;
    --text-colour-max: #fff;
    --text-colour-muted: #666;
    --text-colour-strong: #fff;
    --text-colour-success: #60b367;
    --text-colour-warning: #ba7f00;
    --text-colour: #ccc;
    --tile-bg-colour: #222;
    --tile-callout-bg-colour: #181818;
    --tile-divider-colour: #444;
    --tile-drag-placeholder-border-colour: #444;
    --tile-header-divider-colour: #121212;
    --tile-with-chart-bg-colour: #222;
    --tooltip-bg-colour: #383838;
    --tooltip-box-shadow: 0 0 10px 0 rgba(0, 0, 0, .5);
    --tooltip-colour: #fff;
    --version-colour: #777;
    --work-in-progress-bg-colour: #202020;
    --work-in-progress-border: 5px dashed #333;
    --alert-secondary-bg-colour: #303030;
  }

}
