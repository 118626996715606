@import 'colours';
@import 'fonts';

.modal {
  z-index: 2147483647; // Max
}

.modal-content {
  background: var(--modal-bg-colour);
  box-shadow: var(--modal-box-shadow);
  border: var(--modal-border);
  color: var(--text-colour);
}

.modal-header {
  border-bottom: 1px solid var(--modal-divider-colour);

  .close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    line-height: 1rem;
    font-size: 2.5rem;
    background: transparent;
    border: 0;
  }
}

.modal-footer {
  border-top: 1px solid var(--modal-divider-colour);
}

.modal-footer-content {
  display: flex;
  width: 100%;

  > .center {
    display: flex;
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  > .left {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
  }

  > .right {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    justify-content: flex-end;
    gap: 1rem;
  }
}

.modal-body {
  &.no-padding {
    padding: 0;
  }

  h4 {
    font-size: 1.2rem;
  }

  .form-validation {
    background: var(--form-validation-bg-colour);
    border-radius: 4px;
    color: $white;

    p {
      padding: 4px 8px;
      font-size: 12px;
      display: flex;
      align-items: center;

      &::before {
        font-family: 'Font Awesome 6 Free', sans-serif;
        font-weight: 900;
        font-size: 16px;
        content: '\f071';
        margin-right: .3rem;
      }
    }
  }

  .picklist {
    max-height: 70vh;
    overflow: auto;
    padding: 1rem;
    flex-grow: 1;

    &.fixed-height {
      height: 70vh;
    }
  }
}

.message-box {
  .modal-footer {
    justify-content: center;
    padding: 1.25rem;

    .btn {
      margin: 0 .6rem;
      min-width: 100px;
    }
  }
}
