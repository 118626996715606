// sass-lint:disable no-color-hex

$white: #fff;
$black: #000;

$brand-primary-colour: #e77a35;
$brand-secondary-colour: #d98046;

$brand-primary-colour-muted: #af7752;
$brand-secondary-colour-muted: #d4966e;

$secondary-colour: #337ab7;
