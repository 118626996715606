@import 'fonts';

.daterangepicker {
  font-family: $date-range-picker-font-family;
  background-color: var(--daterangepicker-bg-colour);
  border: 1px solid var(--daterangepicker-border-colour);
  color: var(--text-colour);
  box-shadow: var(--daterangepicker-box-shadow);

  .ranges {
    li:hover {
      background-color: var(--daterangepicker-range-hover-colour);
    }

    li.active {
      background-color: #08c;
      color: #fff;
    }
  }
  .calendar-table {
    background-color: var(--daterangepicker-calendar-table-bg-colour);
    border: 1px solid var(--daterangepicker-calendar-table-border-colour);

    .next span,
    .prev span {
      color: var(--text-colour-inverted);
      border: solid var(--text-colour);
      border-width: 0 2px 2px 0;
    }
  }

  td.available:hover,
  th.available:hover {
    background-color: var(--daterangepicker-available-hover-colour);
  }

  td.week,
  th.week {
    color: var(--daterangepicker-inverted-text-colour);
  }

  td.off,
  td.off.in-range,
  td.off.start-date,
  td.off.end-date {
    background-color: var(--daterangepicker-calendar-table-bg-colour);
    color: var(--daterangepicker-disabled-date-colour);
  }

  td.in-range {
    background-color: var(--daterangepicker-in-range-bg-colour);
    color: var(--daterangepicker-in-range-colour);
  }

  td.active,
  td.active:hover {
    background-color: #357ebd;
    color: #fff;
  }

  td.disabled,
  option.disabled {
    color: var(--daterangepicker-disabled-date-colour);
  }

  select.hourselect,
  select.minuteselect,
  select.secondselect,
  select.ampmselect,
  select.monthselect,
  select.yearselect {
    background: var(--ng-dropdown-panel-bg-colour);
    border: 1px solid var(--ng-dropdown-panel-border-colour);
  }

  .calendar-time select.disabled {
    color: var(--daterangepicker-inverted-text-colour);
  }

  .drp-buttons {
    border-top: 1px solid var(--daterangepicker-divider-colour);
  }

  &.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid var(--daterangepicker-divider-colour);
  }

  &.show-ranges.rtl .drp-calendar.left {
    border-right: 1px solid var(--daterangepicker-divider-colour);
  }

  &.show-ranges.rtl .drp-calendar.right {
    border-right: 1px solid var(--daterangepicker-divider-colour);
  }

  &.show-ranges.ltr .drp-calendar.left {
    border-left: 1px solid var(--daterangepicker-divider-colour);
  }

  &:before {
    border-bottom: 7px solid var(--daterangepicker-border-colour);
  }

  &:after {
    border-bottom: 6px solid var(--daterangepicker-bg-colour);
  }

  &.drop-up:before {
    border-top: 7px solid var(--daterangepicker-border-colour);
  }

  &.drop-up:after {
    border-top: 6px solid var(--daterangepicker-bg-colour);
  }
}

.btn.date-range-opener {
  font-family: $monospace-font-family;
}
