@import 'colours';

.content-header-controls {
  display: grid;
  grid-template-columns: 350px 1fr;
  gap: 2rem;
  margin-bottom: 2rem;

  .radio-buttons {
    .btn-primary {
      border-color: $brand-secondary-colour;
      height: auto;
      line-height: 35px;
      padding: 0 20px;
      color: var(--text-colour);
    }
  }

  .headline-total {
    span {
      text-transform: uppercase;
      font-size: 1.4rem;
      white-space: nowrap;
      line-height: 2.2rem;
    }
  }
}
