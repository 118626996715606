@import 'colours';
@import 'fonts';

.pie-and-timeseries {
  overflow: hidden;
  display: grid;
  align-items: center;
  grid-template-columns: min-content 1fr;
}

highcharts-chart {
  display: block;
  width: 100%;
  border-radius: 5px;

  &.auto-width {
    width: auto;
  }
}

.quality-mini-bar-chart {

  width: 50px;
  display: inline-block;
  vertical-align: middle;

  .valid,
  .suspicious,
  .invalid {
    margin: 2px;
    height: 8px;
    border-radius: 1px 3px 3px 1px;
  }

  .valid {
    background: #018b0e linear-gradient(to bottom, #018b0e 0%, #007a0b 100%);
  }

  .suspicious {
    background: #e0a331 linear-gradient(to bottom, #e0a331 0%, #b8810c 100%);
  }

  .invalid {
    background: #a91523 linear-gradient(to bottom, #a91523 0%, #8f111d 100%) !important;
  }
}

.headline.pie {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headline-pie-chart-unavailable {
  width: 220px;
  margin: 22px 0 0;
}

.chart-annotation {
  font-family: $chart-annotation-font-family;

  h4 {
    font-size: .8rem;
    font-weight: bold;
    padding: 0;
    margin: 0 0 .1rem;
  }
}

.chart-floating-axis-title {
  font-family: $chart-axis-font-family;
  font-size: .8rem;
  color: var(--chart-floating-axis-title-colour);
  background: var(--chart-floating-axis-title-bg-colour);
  border: 1px solid var(--chart-floating-axis-title-border-colour);
  border-bottom: 0;
  padding: .2rem .4rem;
  border-radius: 5px 5px 0 0;
}

.highcharts-plot-line-label {
  overflow: visible !important;
}

.chart-marker-line-label {
  font-family: $chart-annotation-font-family;
  color: var(--text-colour);

  &:after {
    content: '▶';
    font-size: 14px;
    display: block;
    position: relative;
    right: 16px;
    top: -2px;
  }
}

table.chart-key-stats {

  th,
  td {
    font-family: $chart-annotation-font-family;
    color: var(--text-colour);
  }

  th {
    padding-right: .5rem;
  }
}

.chart-tooltip {
  font-family: $chart-tooltip-font-family;
  background: var(--chart-tooltip-bg-colour);
  opacity: .95;
  padding: .4rem .6rem;
  border: 1px solid var(--chart-tooltip-border-colour);
  box-shadow: var(--chart-tooltip-box-shadow);
  color: var(--text-colour);
  font-weight: var(--chart-tooltip-font-weight);
  border-radius: 5px;

  h3 {
    font-weight: bold;
    font-size: .9rem;
    padding: 0;
    margin: 0;
    display: flex;
    gap: .5rem;
    align-items: center;

    img {
      border-radius: 4px;
      width: 16px;
      height: 16px;
    }

    span.bullet {
      display: inline-block;
      width: 16px;
      height: 16px;
      min-width: 16px;
      min-height: 16px;
      border-radius: 4px;
      vertical-align: middle;
      position: relative;
      top: -2px;
      background: rgba(#777, .5);
    }
  }

  h4 {
    font-weight: bold;
    font-size: .8rem;
    padding: 0;
    margin: 0;
  }

  h5 {
    font-weight: bold;
    font-size: .8rem;
    padding: 0;
    margin: 0 0 .3rem;
  }

  table {
    margin-top: .5rem;

    &.tight-margin {
      margin: -.25rem;
    }

    thead {
      background: var(--chart-tooltip-header-bg-colour);

      th {
        font-weight: bold;
        padding: .1rem .4rem .1rem 0;

        &:first-child {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }

        &:last-child {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
    }

    tbody {
      th {
        padding: .1rem 1rem .1rem 0;

        >div {
          display: flex;
          align-items: center;
          gap: .1rem;
        }
      }

      td {
        padding: .1rem 1rem .1rem 0;
        font-weight: bold;

        &:last-child {
          padding-right: 0;
        }
      }

      tr.footer {
        background: rgba(#777, .1);
        border-top: 1px solid rgba(#777, .2);

        td {
          font-weight: bold;
        }
      }
    }

    span.bullet {
      display: inline-block;
      width: 10px;
      height: 10px;
      min-width: 10px;
      min-height: 10px;
      border-radius: 3px;
      vertical-align: middle;
      margin-right: .4rem;
      background: rgba(#777, .5);
    }
  }
}

.chart-no-data {
  flex-grow: 1;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  &.tall {
    height: 350px;
  }

  div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
    color: var(--chart-no-data-colour);
    font-weight: bold;
    font-size: 120%;
  }

  span {
    i {
      display: inline-block;
      color: var(--chart-no-data-colour);
      margin-bottom: .5rem;
    }

    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 120%;
    color: var(--chart-no-data-colour);
  }
}

.highcharts-tooltip-container {
  z-index: 999999 !important;
}

.inline-donut-headline {
  color: var(--text-colour);
  text-align: center;

  a {
    text-decoration: none;
    color: var(--text-colour) !important;
  }

  strong {
    display: block;
    font-size: 2rem;
    font-family: $brand-font-family;
    line-height: 2rem;
  }

  span {
    font-size: .8rem;
    font-family: $labels-font-family;
    line-height: 1rem;
  }

  &.semi {
    strong {
      font-size: 1.5rem;
    }
  }

  &.small {
    strong {
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    span {
      font-size: .7rem;
      line-height: .8rem;
    }
  }

  &.mini {
    strong {
      font-size: 1.1rem;
      line-height: 1.1rem;

      &.shrink {
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }
}

// Fix a stupid bug introduced by Highcharts some time before July 2023 that caused massive page scrollbars when a chart tooltip was displayed outside the chart area
.highcharts-tooltip-container {
  z-index: 999999 !important;
  overflow: hidden;
  bottom: 0;
  right: 0;
}

// Style for custom positioned tooltip
.influence-chart-tooltip {
  font-size: 0.7rem;
  line-height: 0.7rem;
  box-shadow: none;
  border: none;
  border-radius: 0;
  width: 100%;
  padding-right: 0.1rem;

  table {
    height: 427px;
    overflow-y: scroll;
    display: block;

    thead th,
    tfoot tr {
      position: sticky;
      background: #272727;
      border-top: 1px solid #333333;
      color: #939393;
    }

    thead tr th {
      padding: 3px 7px 3px 3px;
    }

    tbody td {
      .percentage {
        color: #7bb1f2;

        .na {
          color: #838383;
        }
      }

      .metric-value {
        color: #838383;
        font-weight: normal;
      }
    }

    thead th {
      top: 0;
    }

    tfoot tr {
      bottom: 0;

      td {
        padding: 3px 0 3px 1px;
        font-weight: bold;
      }
    }

    tbody {
      margin: 0;
      padding: 0;
    }

    tbody tr:first-of-type td {
      padding-top: 10px;
    }

    tbody tr:last-of-type td {
      padding-bottom: 10px;
    }
  }
}

.recommendation-line-chart {
  margin-bottom: 10px;

  .chart-tooltip {
    height: 60px;
  }
}

.recommendation-bar-chart {
  .highcharts-title {
    font-size: 0.8em !important;
  }

  .highcharts-data-label {
    font-weight: 0.5em !important;
    opacity: 0.3;
  }

  .bar-chart-tooltip {
    background-color: #0d0d0d;
    height: 60px;
    width: 160px;
    opacity: 0.85 !important;
    border-radius: 3px;
    border: 1px solid #434343;
    span {
        color: #b9b9b9 !important
    }
  }
}