@import 'colours';
@import 'fonts';

.tooltip-inner {
  max-width: 500px;
}

.tooltip.error {
  .tooltip-inner {
    background-color: #a00;
    color: #fff;
  }

  &.top {
    .tooltip-arrow::before,
    .tooltip-arrow {
      border-top-color: #a00;
    }
  }

  &.bottom {
    .tooltip-arrow::before,
    .tooltip-arrow {
      border-bottom-color: #a00;
    }
  }
}

.tooltip {
  font-family: $tooltip-font-family;
  font-size: 90%;

  .tooltip-inner {
    background-color: var(--tooltip-bg-colour);
    box-shadow: var(--tooltip-box-shadow);
    color: var(--tooltip-colour);
  }

  &.top {
    .tooltip-arrow::before,
    .tooltip-arrow {
      border-top-color: var(--tooltip-bg-colour);
    }
  }

  &.bottom {
    .tooltip-arrow::before,
    .tooltip-arrow {
      border-bottom-color: var(--tooltip-bg-colour);
    }
  }

  &.left {
    .tooltip-arrow::before,
    .tooltip-arrow {
      border-left-color: var(--tooltip-bg-colour);
    }
  }

  &.right {
    .tooltip-arrow::before,
    .tooltip-arrow {
      border-right-color: var(--tooltip-bg-colour);
    }
  }
}

.tooltip.show {
  opacity: 1;
}

.tooltip.text-with-icon-list {
  text-align: left;
  font-family: $tooltip-font-family;
  font-size: 80%;

  ul {
    margin: .2rem;

    li {
      white-space: nowrap;
      display: flex;
      gap: 0 .3rem;
      align-items: center;

      img {
        width: 12px;
        height: 12px;
        border-radius: 3px;
      }
    }
  }
}

span[ng-reflect-tooltip], label[ng-reflect-tooltip] {
  cursor: help;
}

// Fix compatibility issue with Bootstrap 5.2: https://github.com/valor-software/ngx-bootstrap/issues/6490
.popover { position: absolute; top: 0; left: 0 #{'/* rtl:ignore */'}; }

.popover-arrow { position: absolute; }

.tooltip { position: absolute; }

.tooltip-arrow { position: absolute; }

// End Fix
