@import 'colours';
@import 'fonts';

body {
  position: relative;
  overflow-y: scroll;
  --scrollbar-width: 17px;
}

body,
html {
  --min-page-width: 1280px;
  min-width: var(--min-page-width);
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

.padded-content {
  padding: 0 65px;
  overflow: hidden;
  flex-grow: 1;

  &.top-bottom-padding {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  &.top-padding {
    padding-top: 40px;
  }

  &.bottom-padding {
    padding-bottom: 40px;
  }
}

#portal {
  background: #161616;
  min-height: 100vh;
  display: flex;

  --leftnav-width: 63px;
  --leftnav-expanded-width: 182px;

  section {
    &.page-area {
      background: var(--page-bg-colour);
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &.dark {
    header#black {
      border-bottom: 1px solid #454545;
    }

    #content {
      background: #161616;
    }
  }

  &.special {
    #content {
      background: var(--page-bg-colour-special);

      section {
        &.page-area {
          background: var(--page-bg-colour-special);
        }
      }
    }
  }
}

#content {
  background: var(--page-bg-colour);
  flex-grow: 1;

  .right-content {
    .top-header {
      height: 70px;
      top: 0;
      z-index: 2000;
    }
  }
}

.no-horizontal-scroll {
  max-width: calc(max(var(--min-page-width) - var(--leftnav-width) - var(--scrollbar-width), 100vw - var(--leftnav-width) - var(--scrollbar-width)));
  position: sticky !important;
  left: var(--leftnav-width);
  z-index: 100;
}

#portal.leftnav-expanded .no-horizontal-scroll {
  max-width: calc(max(var(--min-page-width) - var(--leftnav-expanded-width) - var(--scrollbar-width), 100vw - var(--leftnav-expanded-width) - var(--scrollbar-width)));
  left: var(--leftnav-expanded-width);
}

.padded-content .no-horizontal-scroll {
  max-width: calc(max(var(--min-page-width) - var(--leftnav-width) - var(--scrollbar-width), 100vw - 130px - var(--leftnav-width) - var(--scrollbar-width)));
  left: 0;
}

#portal.leftnav-expanded .padded-content .no-horizontal-scroll {
  max-width: calc(max(var(--min-page-width) - var(--leftnav-expanded-width) - var(--scrollbar-width), 100vw - 130px - var(--leftnav-expanded-width) - var(--scrollbar-width)));
}

section.white.no-horizontal-scroll {
  overflow: visible;
  z-index: 500;

  .padded-content {
    overflow: visible;
  }
}
