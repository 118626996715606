@import 'colours';
@import 'fonts';

body {
  font-family: $brand-font-family;
  font-size: 14px;
  color: var(--text-colour);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-colour);
}

a {
  color: $brand-secondary-colour;
  outline: 0;
  text-decoration: none;

  &:hover {
    color: $brand-primary-colour;
    outline: 0;
    text-decoration: underline;
  }

  &:active {
    outline: 0;
    text-decoration: underline;
  }

  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}

code,
pre {
  color: var(--text-colour);
  font-family: $monospace-font-family;
  font-size: 100%;
}

pre {
  white-space: pre-wrap;
}

.pre-wrap {
  white-space: pre-wrap;
}

var {
  font-family: $monospace-font-family;
  font-weight: bold;
}

.uppercase { text-transform: uppercase; }

p.center { text-align: center; }

.heading-with-controls {
  align-items: center;
  display: flex;
  gap: 2rem;

  margin-bottom: .5rem;

  h3 {
    margin: 0;
  }
}

.text-muted {
  color: var(--text-colour-muted) !important;
}

.percentage-change, .percentage-difference {
  color: var(--text-colour);
  font-size: .75rem;
  font-weight: normal;
  margin: 0 0 0 .5rem;
  white-space: nowrap;

  &.good {
    color: var(--text-colour-success);
  }

  &.bad {
    color: var(--text-colour-danger);
    opacity: .9;
  }
}

.score {
  border: 1px dashed var(--score-border-colour);
  border-radius: 50%;
  color: var(--score-colour);
  display: inline-block;
  font-family: $labels-font-family;
  height: 30px;
  line-height: 28px;
  text-align: center;
  width: 30px;

  &.bad {
    border: 1px dashed var(--score-bad-colour);
    color: var(--score-bad-colour);
  }

  &.medium {
    border: 1px dashed var(--score-medium-colour);
    color: var(--score-medium-colour);
  }

  &.good {
    border: 1px dashed var(--score-good-colour);
    color: var(--score-good-colour);
  }
}

.score-label {
  &.bad {
    color: var(--score-bad-colour);
  }

  &.medium {
    color: var(--score-medium-colour);
  }

  &.good {
    color: var(--score-good-colour);
  }
}

.label-tags {
  margin: 0;
  text-transform: uppercase;

  li {
    margin: 4px;

    span {
      background: var(--label-tags-colour);
      border-radius: 2px;
      color: $white;
      display: inline-flex;
      align-items: center;
      gap: .2rem;
      font-weight: normal;
      padding: 3px 7px;
      white-space: nowrap;

      &.info {
        background: var(--label-tags-info-colour);
        color: $white;
      }

      &.success {
        background: var(--label-tags-success-colour);
        color: var(--label-tags-success-text-colour);
      }

      &.warning {
        background: var(--label-tags-warning-colour);
        color: $black;
      }

      &.danger {
        background: var(--label-tags-danger-colour);
        color: $white;
      }
    }
  }

  &.small {
    li {
      font-size: 85%;
    }
  }
}

.recommended-action {
  font-size: .75rem;
  font-weight: normal;
  white-space: nowrap;
  border-radius: 5px;
  padding: .1rem .5rem;
  width: auto;
  vertical-align: middle;
  display: inline-flex;
  align-items: center;
  gap: .3rem;
  background: rgba(#777, .2);
  color: var(--text-colour) !important;
  transition: color .5s ease;
  justify-content: center;

  &.up {
    background: #007a0b;
    color: $white !important;
  }

  &.down {
    background: #265b89;
    color: $white !important;
  }

  i { margin-right: .3rem; }

  .change {
    margin: .05rem -.35rem .05rem 0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: .3rem;

    .by {
      font-weight: normal;
    }

    .percentage {
      display: block;
      background: rgba(0, 0, 0, .25);
      border-radius: 5px;
      padding: 0 .4rem;
      font-size: 130%;
    }
  }
}

.recommended-action-wrap {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin-top: .2rem;
}

.recommended-action-detail {
  display: flex;
  align-items: center;
  gap: 1rem;
  white-space: nowrap;
  margin-top: .25rem;

  .percentage {
    font-weight: bold;
    font-size: 130%;
    color: $white !important;
    padding: 0 .25rem;
  }

  .value {
    font-size: 130%;
    display: flex;
    align-items: center;
    gap: .3rem;
  }

  .value::after {
    white-space: nowrap;
    font-size: .7rem;
    font-weight: normal;
    font-family: $labels-font-family;
    opacity: .7;
  }

  .value.daily::after {
    content: 'tomorrow';
  }

  .value.weekly::after {
    content: 'next week';
  }

  .value.monthly::after {
    content: 'next month';
  }

  .value.quarterly::after {
    content: 'next quarter';
  }

  .value.yearly::after {
    content: 'next year';
  }

  .value::before {
    white-space: nowrap;
    content: 'to';
    font-size: .7rem;
    font-weight: normal;
    font-family: $labels-font-family;
    opacity: .7;
  }

  .suffix {
    opacity: .5;
    font-size: 70%;
    font-weight: normal;
  }
}

.text-with-icon {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  gap: .5rem;

  img {
    border-radius: 5px;
    height: 32px;
    width: 32px;

    &.size-16 {
      border-radius: 3px;
      height: 16px;
      width: 16px;
    }

    &.size-20 {
      border-radius: 4px;
      height: 20px;
      width: 20px;
    }

    &.size-24 {
      border-radius: 4px;
      height: 24px;
      width: 24px;
    }
  }
}

.try-it {
  background: var(--callout-bg-colour);
  margin: 2rem 0;
  padding: 1.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 3rem;

  .description {
    flex-grow: 1;

    h3 { font-size: 1.4rem; }

    p { margin: 1rem 0 0; }

    .detail-with-icon {
      display: flex;
      gap: 1rem;

      i {
        margin: 1rem 0 0;
        font-size: 40px;
        opacity: .5;
      }
    }
  }

  button {
    width: 250px;
    margin-right: 1rem;
  }
}
