@import 'fonts';

.metric.tile {

  .columns {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(1px, 1fr));
    gap: 2rem;
  }

  > .columns {
    padding: 1rem;
  }

  label, .form-group label, button {
    font-family: $labels-font-family;
    text-transform: none;
    font-size: .8rem;
    white-space: nowrap;
  }

  .switch {
    top: -4px;
    zoom: 80%;
  }

  button.btn {
    height: unset;
    min-width: unset;
  }

  .form-control, .ng-select-container {
    background: var(--callout-form-control-bg-colour) !important;
    border-color: var(--callout-form-control-border-colour) !important;
    font-size: .8rem;
    border-radius: 3px;
  }

  .form-control {
    padding: .2rem .4rem;
  }

  .form-group {
    margin: 0 0 14px;

    &.no-margin {
      margin: 0;
    }

    &.with-switch {
      display: flex;
      align-items: center;
      gap: 1rem;
      justify-content: space-between;
    }
  }

  .radio-buttons .btn {
    line-height: unset;
    padding: .2rem .4rem;
  }

  .views {
    display: grid;
    gap: .2rem 1rem;
    grid-template-columns: 100fr 1fr 1fr;
  }
}
