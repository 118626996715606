@import 'fonts';
@import 'colours';

.key-stat.tiles {
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));

  .key-stat-label {
    opacity: .7;
    font-family: $labels-font-family;
  }

  .key-stat-value {
    font-size: 1.6rem;
    font-weight: bold;

    &.warning { color: var(--text-colour-warning); }

    &.good { color: var(--text-colour-success); }

    &.bad { color: var(--text-colour-danger); }

    .percentage-change {
      font-size: 1.1rem;
      font-weight: bold;
      position: relative;
      top: -2px;
    }
  }

  .tile {
    &.banner {
      flex-direction: row;
      gap: 1.5rem;
      align-items: stretch;

      header {
        border: 0;
        margin-right: -1rem;
      }

      .stat-with-label {
        display: flex;
        gap: 1rem;
        align-items: center;
        border-radius: 5px;
        background: var(--tile-callout-bg-colour);
        margin: 1.5rem 0;
        padding: .25rem 1rem;

        &:last-child {
          flex-grow: 1;
          margin-right: 1.5rem;
        }

        .key-stat-value {
          display: flex;
          gap: 1rem;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &.chart {

    .tile {
      .stats {
        margin: 1.5rem;
        display: flex;
        align-items: stretch;
        justify-items: stretch;
        gap: 1.5rem;

        .stat-with-label {
          border-radius: 5px;
          background: var(--tile-callout-bg-colour);
          padding: .25rem 1rem;
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .key-stat-value {
            display: flex;
            align-items: center;
            gap: .5rem;

            highcharts-chart {
              width: auto;
            }
          }
        }
      }

      .gauge {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 5px;
        background: var(--tile-callout-bg-colour);
        margin: 0 1.5rem 1.5rem;

        highcharts-chart {
          margin: 0 -1.5rem -1.5rem;
          width: auto;
        }

        .target {
          margin: 0 1.5rem 1rem;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: $labels-font-family;

          .key-bullet {
            display: inline-block;
            width: 12px;
            height: 12px;
            border-radius: 3px;
            background: var(--score-good-colour);
            margin-left: 0;
          }

          .label {
            margin-left: .4rem;
          }

          .to {
            opacity: .7;
            margin-left: .5rem;
          }

          strong {
            font-size: 1.1rem;
            margin-left: .5rem;

            &:first-of-type {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}
