@import 'mixins';

.clearfix { @include clearfix; }

.hide { @include hide; }

.ellipsis { @include ellipsis; }

.word-wrap { @include word-wrap; }

.relative { position: relative; }

.nowrap { white-space: nowrap; }

.word-break { word-break: break-all; }

.printonly { display: none; }

.clickable { cursor: pointer; }

.avatar { border-radius: 999px; }

.no-padding { padding: 0 !important; }

.float-left { float: left; }

.float-right { float: right; }

[hidden] {
  display: none !important;
}

:focus {
  outline: none;
}

@media print {
  .noprint { display: none !important; }
  .printonly { display: block; }
}

.fill-space { display: grid; }
