table.switches {
  td {
    padding: 5px 20px;
    vertical-align: middle;

    &:first-child { padding-left: 0; }

    &:last-child { padding-right: 0; }

    &.name { font-weight: bold; }

    &.description { opacity: .7; }
  }
}
