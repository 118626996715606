.controls {
  display: grid;
  gap: 0 1rem;

  > label {
    min-width: 200px;
  }

  &.inline {
    display: flex;
    flex-direction: row;
    gap: 0;
    align-items: center;
    justify-items: flex-start;

    > * {
      width: 200px;
      margin: 0 0 0 3rem;

      &:first-child {
        margin-left: 0;
      }
    }

    > .spacer { flex-grow: 1; }

    > label {
      width: auto;
      min-width: auto;
      margin-right: -2rem;
    }
  }
}
